// function setImgBlobPath(n, t) {
//     const e = new XMLHttpRequest;
//     e.open("get", t, !0), e.responseType = "blob", e.onload = function () {
//         if (200 === this.status) {
//             const t = this.response;
//             n.src = "", n.onload = function () {
//                 window.URL.revokeObjectURL(n.src)
//             }, n.src = window.URL.createObjectURL(t)
//         }
//     }, e.send()
// }


// 禁止右键
window.document.oncontextmenu = function () {
    return !1
};

// 禁止粘贴
window.document.onkeydown = function (e) {
    if (e.ctrlKey && e.code === "KeyC") {
        console.log('右键复制 监听成功');
        return false;
    }
}
window.document.oncopy = function () {
    console.log('右键复制 监听成功');
    return false;
}

// function mAlert() {
//     alert("感谢使用管理平台，禁止对控制台进行操作！");
// }

// 禁止F12
window.onkeydown = window.onkeyup = function (e) {
    if (e.code === 'F12') {
        // mAlert();
        e.preventDefault();
        return false;
    }
}

// 禁止检查
setInterval(function () {
    debugger;
}, 100)


