import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

// 存储数据
const state = {
    document: false,
    folder: false
}
// 响应组件操作
const actions = {
    document(context, value) {
        context.commit('DOCUMENT', value);
    },
    folder(context, value) {
        context.commit('FOLDER', value);
    }
}
// 操作数据
const mutations = {
    DOCUMENT(state, value) {
        state.document = value;
    },
    FOLDER(state, value) {
        state.folder = value;
    }
}
const getters = {}
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})