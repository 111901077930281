import request from "../utils/request";

// // 文件列表
// export const list = () => request({url: '/api1/pic/list', method: 'get'})
//
// // 新建相册
// export const folderUp = (data) => request({url: '/api1/folder/add', method: 'post', data})
//
// // 上传图片
// export const picUp = (data) => request({url: '/api1/pic/add', method: 'post', data, config:{
//     'Content-type': 'multipart/form-data'
// },});
// // export const picUp = (data) => request.post('/api/pic/add',data,{'Content-type': 'multipart/form-data'});
//
//
// // 提交图片
// export const picSubmit = (data) => request({url: '/api/submit/add', method: 'post', data});

export const apiBizs = () => request({url: '/api1/api/api/apiBizs', method: 'get'})

export const apiBindDailyPic = () => request({url: '/api1/api/api/bindDailyPic', method: 'get'})

export const apiLoginQr = () => request({url: '/api2/web.php/login/pcQR', method: 'get'})
export const apiLoginCheck = (data) => request({url: '/api2/web.php/login/check', method: 'get', params: data})
