import axios from "axios"

const service = axios.create({
    // baseURL: "http://thinkphp6.gqqf.xyz/",
    timeout: 60000
})

service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        console.log('err :' + error)
    }
)

service.interceptors.response.use(
    response => {
        if(response.status === 200){
            return JSON.parse(JSON.stringify(response.data))
        }else{
            throw Error('请求异常')
        }
    },
    error => {
        console.log('err :' + error)
    }
)

export default service
