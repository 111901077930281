<template>
  <div id="app">
    <Biz />
  </div>
</template>

<script>
import Biz from '@/pages/biz/Biz'
import "@/assets/css/global.css"

export default {
  name: 'App',
  components: {Biz},
  data() {
    return { }
  },
  methods: { }
}
</script>

<style lang="scss">
  .single_hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .second_hidden {
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
</style>
