<template>
  <div v-if="qrcode">
    <el-dialog
      custom-class="my-dialog"
      title="微信扫一扫登录"
      :visible="true"
      width="360px"
      :close-on-click-modal="false"
      :before-close="closeLogin"
      :modal-append-to-body="false"
      :showClose="false"
    >
      <el-row class="row">
        <img :src="qrcode" width="300">
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Login',
  props: {
    qrcode: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeLogin() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
.row {
  text-align: center;
}
</style>
