<template>
  <div class="biz">
    <div class="container">

      <div class="main" v-if="false">
        <h1>地址发布页</h1>
        <h2>请 Ctrl+D 收藏本页到浏览器收藏夹</h2>

        <div class="content">
            <div class="content-top">
              <h2 @click="navTo('')">设计类</h2>
              <ul>
                <li class="single_hidden" v-for="item in link1" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i class="single_hidden" >{{item.url}}</i></a>
                </li>
              </ul>
            </div>
            <div class="content-top">
              <h2>推荐类</h2>
              <ul>
                <li v-for="item in link3" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                </li>
              </ul>
            </div>
            <div class="content-top">
              <h2>编程类</h2>
              <ul>
                <li v-for="item in link2" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                </li>
              </ul>
            </div>
        </div>

      </div>

      <div class="main">
        <el-row :gutter="12" class="item">

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <h1>地址发布页</h1>
            <h2>请 Ctrl+D 收藏本页到浏览器收藏夹</h2>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="content-top">
              <h2>推荐类</h2>
              <ul>
                <li v-for="item in link3" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                </li>
              </ul>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="content-top">
              <h2 @click="navTo('')">设计类</h2>
              <ul>
                <li class="single_hidden" v-for="item in link1" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i class="single_hidden" >{{item.url}}</i></a>
                </li>
              </ul>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="content-top">
              <h2 @click="navTo('')">色彩类</h2>
              <ul>
                <li class="single_hidden" v-for="item in link4" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i class="single_hidden" >{{item.url}}</i></a>
                </li>
              </ul>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="content-top">
              <h2>编程类</h2>
              <ul>
                <li v-for="item in link2" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                </li>
              </ul>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="content-top">
              <h2>影视类</h2>
              <ul>
                <li v-for="item in link5" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                </li>
              </ul>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="content-top">
              <h2>在线工具类</h2>
              <ul>
                <li v-for="item in link7" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                </li>
              </ul>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="content-top">
              <h2>其他类</h2>
              <ul>
                <li v-for="item in link6" :key="item.id">
                  {{item.name}}
                  <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                </li>
              </ul>
            </div>
          </el-col>

          <!--        <div class="content">
                      <div class="content-top">
                        <h2 @click="navTo('')">设计类</h2>
                        <ul>
                          <li class="single_hidden" v-for="item in link1" :key="item.id">
                            {{item.name}}
                            <a :href="item.url" target="_blank"><i class="single_hidden" >{{item.url}}</i></a>
                          </li>
                        </ul>
                      </div>
                      <div class="content-top">
                        <h2>推荐类</h2>
                        <ul>
                          <li v-for="item in link3" :key="item.id">
                            {{item.name}}
                            <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                          </li>
                        </ul>
                      </div>
                      <div class="content-top">
                        <h2>编程类</h2>
                        <ul>
                          <li v-for="item in link2" :key="item.id">
                            {{item.name}}
                            <a :href="item.url" target="_blank"><i>{{item.url}}</i></a>
                          </li>
                        </ul>
                      </div>
                  </div>-->
        </el-row>
      </div>


      <div class="foot">
        <p>电脑请安装<a href="https://www.google.cn/chrome" target="_blank"> chrome浏览器 </a>访问，手机请安装<a href="https://www.xbext.com" target="_blank"> X浏览器 </a>访问。</p>
        <p>© 2020 - {{ now }} GQQF. All Rights Reserved <a href="https://beian.miit.gov.cn/#/Integrated/index"> 皖ICP备2020021352号-2 </a></p>
        <p>技术支持 <a href="https://www.gqqf.xyz/" target="_blank"> 周先森网络科技 </a></p>
      </div>

      <Login ref="Login" :qrcode="qrCode" @close="closeLogin" />

      <ul class="bg-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
import {apiBindDailyPic, apiBizs, apiLoginCheck, apiLoginQr} from "@/api";
import {getToken, setToken} from "@/utils/auth";
import Login from "@/components/global/Login";

export default {
  name: "Biz",
  components: {Login},
  data() {
    return {
      bg: '',
      now: 2023,
      setIntervalId: 0,
      qrCode: '',
      link1: [],
      link2: [],
      link3: [],
      link4: [],
      link5: [],
      link6: [],
      link7: [],
    }
  },
  methods: {
    navTo(item) {
      const w = window.open("about:blank");
      w.location.href = item.url || '/'
    },

    closeLogin() {
      // this.qrCode = ''
      // if (this.setIntervalId) {
      //   clearInterval(this.setIntervalId)
      // }
    },
  },
  mounted() {
    document.title = '链接导航-周先森资源'

    apiBizs().then(res=>{
      let data = res.data.api_bizs;
      if(data) {
        this.link1 = data.api1;
        this.link2 = data.api2;
        this.link3 = data.api3;
        this.link4 = data.api4;
        this.link5 = data.api5;
        this.link6 = data.api6;
        this.link7 = data.api7;
      }
    });

    apiBindDailyPic().then(res=>{
      let data = res.data;
      if(data) {
        this.bg = data.api_bindDailyPic;
        // window.document.body.style.backgroundImage = this.bg;
        window.document.body.style.background = 'url('+this.bg+')';
        window.document.body.style.backgroundAttachment = 'fixed';
      }
    });

    if(!getToken()) {
      apiLoginQr().then(res=>{
        this.qrCode = res.data.qrcode
        this.setIntervalId =  setInterval(() => {
          apiLoginCheck({
            code: res.data.code
          }).then(res => {
            if (res.data.login) {
              this.$message({
                message: res.message,
                type: 'success',
                duration: 1500
              })
              setToken(res.data.token)
              window.location.reload()
            }
          })
        }, 3000)
      })
    }

    let unlock = false
    document.addEventListener('allow_copy', (event) => {
      unlock = event.detail.unlock
    })
    const copyEvents = [
      'copy',
      'cut',
      'contextmenu',
      'selectstart',
      'mousedown',
      'mouseup',
      'mousemove',
      'keydown',
      'keypress',
      'keyup',
    ]
    const rejectOtherHandlers = (e) => {
      if (unlock) {
        e.stopPropagation()
        if (e.stopImmediatePropagation) e.stopImmediatePropagation()
      }
    }
    copyEvents.forEach((evt) => {
      document.documentElement.addEventListener(evt, rejectOtherHandlers, {
        capture: true,
      })
    })

    this.now = new Date().getFullYear();
  }
}
</script>

<style>
body {
  background-repeat: no-repeat;
}
</style>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 300
}

.container {
  position: relative;
  z-index: 999;
  overflow: hidden;

  .main {
    padding: 40px 0 20px;

    h1,h2 {
      color: #fff;
      font-size: 40px;
      text-align: center;
      font-weight: bolder;
    }

    h2 {
      font-size: 28px;
      margin: 28px auto;
      padding: 0 20px;
    }

    .content {
      width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 5px;

      .content-top {
        width: 600px;
        overflow: hidden;
        box-sizing: border-box;
        padding: 20px;
        background: #fff;
        max-width: 100%;
        height: 640px;
        margin: 0 20px;
        border-radius: 4px;

        h2 {
          cursor: pointer;
          color: #fff;
          font-size: 22px;
          text-align: center;
          background: #02a982;
          padding: 12px 0;
          border-radius: 5px
        }
        ul {
          width: 150%;
          height: 480px;
          overflow-y: scroll;
          li {
            width: 68%;
            display: block;
            font-size: 14px;
            color: #000;
            line-height: 1.8em;
            padding: 12px;
            border-bottom: 1px solid #e2e0de;
            &:last-child {
              border-bottom: none;
            }

            a {
              color: #000;
              font-weight: 400;

              i {
                width: 80%;
                color: #02a982;
                font-style: normal;
                display: block;
              }
            }
          }
        }
      }
    }

    .content-top {
      //width: 600px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 20px;
      background: rgba(255, 255, 255, 0.88);
      max-width: 100%;
      height: 292px;
      margin: 20px;
      border-radius: 4px;

      h2 {
        margin: 12px auto;
        cursor: pointer;
        color: #fff;
        font-size: 22px;
        text-align: center;
        background: #02a982;
        padding: 12px 0;
        border-radius: 4px;
        font-weight: bolder;
      }
      ul {
        margin-top:  24px;
        width: 150%;
        height: 168px;
        overflow-y: scroll;
        li {
          width: 68%;
          display: block;
          font-size: 16px;
          font-weight: bolder;
          color: #000;
          line-height: 1.8em;
          padding: 12px;
          border-bottom: 1px solid #e2e0de;
          overflow: hidden;
          &:last-child {
            border-bottom: none;
          }

          a {
            color: #000;
            font-weight: 400;

            i {
              width: 80%;
              color: #02a982;
              font-style: normal;
              display: block;
            }
          }
        }
      }
    }
  }

  .foot {
    bottom: 20px;
    text-align: center;
    width: 100%;
    color: #fff;
    background: #141e30;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, rgb(20, 30, 48), rgb(36, 59, 85));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, rgb(20, 30, 48), rgb(36, 59, 85)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-weight: bolder;
    font-size: 14px;
    padding: 20px;

    p {
      margin-bottom: 4px;
    }

    .now {
      font-weight: 500
    }

    a, a:link, a:visited, a:active {
      font-weight: 500;
      margin-top: 30px;
      text-decoration: underline;
    }

    a:hover {
      color: blue;
      transition: .5s all;
      text-decoration: underline;
    }
  }

  .bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    li {
      position: absolute;
      list-style: none;
      display: block;
      width: 40px;
      height: 40px;
      background-color: hsla(0, 0%, 100%, .15);
      bottom: -160px;
      animation: square 25s linear infinite;
      transition-timing-function: linear
    }

    li:nth-child(1) {
      left: 10%
    }

    li:nth-child(2) {
      left: 20%;
      width: 80px;
      height: 80px;
      animation-delay: 2s;
      animation-duration: 17s
    }

    li:nth-child(3) {
      left: 25%;
      animation-delay: 4s
    }

    li:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      animation-duration: 22s;
      background-color: hsla(0, 0%, 100%, .25)
    }

    li:nth-child(5) {
      left: 70%
    }

    li:nth-child(6) {
      left: 80%;
      width: 120px;
      height: 120px;
      animation-delay: 3s;
      background-color: hsla(0, 0%, 100%, .2)
    }

    li:nth-child(7) {
      left: 32%;
      width: 160px;
      height: 160px;
      animation-delay: 7s
    }

    li:nth-child(8) {
      left: 55%;
      width: 20px;
      height: 20px;
      animation-delay: 15s;
      animation-duration: 40s
    }

    li:nth-child(9) {
      left: 25%;
      width: 10px;
      height: 10px;
      animation-delay: 2s;
      animation-duration: 40s;
      background-color: hsla(0, 0%, 100%, .3)
    }

    li:nth-child(10) {
      left: 90%;
      width: 160px;
      height: 160px;
      animation-delay: 11s
    }
  }
}

@keyframes square {
  0% {
    transform: translatey(0)
  }
  to {
    transform: translatey(-700px) rotate(600deg)
  }
}
</style>
